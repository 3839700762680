<template>
  <va-data-table :data="items" :fields="fields" class="elevation-1">
    <template slot="actions" slot-scope="props">
      <va-button medium style="width: 150px;" @click="editRow(props.rowData)">Edit Rule</va-button>
      <va-button medium style="width: 150px;" @click="removeRow(props.rowData)">Delete Rule</va-button>
      <va-button medium style="width: 150px;" @click="editRow(props.rowData)">Add Comment</va-button>
    </template>
  </va-data-table>
</template>

<script>
export default {
  
  props: {
    items: {
      type: Array,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
    isCheckedArray: Array,    
  },
  methods: {
    removeRow(item) {
      // Find the index of the item in the items array
      const index = this.items.indexOf(item);

      // Remove the item from the array
      if (index !== -1) {
        this.items.splice(index, 1);
      }
    },
    editRow(itemData) {
      this.$emit("editRule",itemData)
    },
  },
};
</script>

<style lang="scss" scoped>
.v-data-table .v-data-table-header th {
  font-size: 100px; /* You can adjust the font size to your preference */
}
</style>